// import logo from './logo.svg';
import './App.css';
import React from 'react';

import Header from './components/Header';
import Links from './components/Link';
// import Footer from './components/Footer';

function App() {
	return (
		<div className='App'>
			<Header />
			<Links />
			{/* <Footer /> */}
		</div>
	);
}

export default App;
